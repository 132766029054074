let path="";
////www.hankahuanbao.com/static/images/index/banner1.jpg
let image = {
   index_banner_1:`${path}/images/index/banner1.jpg`,//"@/assets/img/home_banner.png",
   index_banner_2:`${path}/images/index/banner2.jpg`,
   index_banner_3:`${path}/images/index/banner3.jpg`,

   //优秀案例
   index_goods_1:`${path}/images/goods/1.jpg`,
   index_goods_2:`${path}/images/goods/2.jpg`,
   index_goods_3:`${path}/images/goods/3.jpg`,
   index_goods_4:`${path}/images/goods/4.jpg`,
   index_goods_5:`${path}/images/goods/5.jpg`,
   index_goods_6:`${path}/images/goods/6.jpg`,
   index_goods_7:`${path}/images/goods/7.jpg`,
   index_goods_8:`${path}/images/goods/8.jpg`,

   //合作伙伴
   "partner_1":path+"/images/index/partner_1.jpg",
   "partner_2":path+"/images/index/partner_2.jpg",
   "partner_3":path+"/images/index/partner_3.jpg",
   "partner_4":path+"/images/index/partner_4.jpg",
   "partner_5":path+"/images/index/partner_5.jpg",
   "partner_6":path+"/images/index/partner_6.jpg",
   "partner_7":path+"/images/index/partner_7.jpg",
   "partner_8":path+"/images/index/partner_8.jpg",
}

export default image
