
  export default{
     props:{
       name:String,
       default:"",
     },
     mounted(){

     }
  }
