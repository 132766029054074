import { render, staticRenderFns } from "./index.vue?vue&type=template&id=394085ea&scoped=true"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"
import style0 from "./index.vue?vue&type=style&index=0&id=394085ea&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "394085ea",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppTitle: require('/www/wwwroot/pc.hankahuanbao.com/components/app-title.vue').default,AppImage: require('/www/wwwroot/pc.hankahuanbao.com/components/app-image.vue').default})
