
  import imgCfg from "../config/image.js";
  export default{
     props: {
       url: String,
       imgClass:String,
       placeholderClass:String
     },
     data(){
       return{
         src: "",
         placeholder:"./images/placeholder.png",
         imgCfg:imgCfg
       }
     },
     watch: {
       url(newVal) {
         if (this.url.indexOf('://') > -1 || this.url.indexOf('data:image/')>-1) {
           this.src=this.url;
         }else{
           if(this.url!=""){
             this.src=imgCfg[this.url];
           }else{
             this.src="";
           }
         }
       }
     },
     mounted(){
       this.handleSrc();
     },
     methods:{
       handleSrc() {
         if (this.url.indexOf('://') > -1 || this.url.indexOf('data:image/')>-1) {
           this.src=this.url;
         }else{
           if(this.url!=""){
             this.src=imgCfg[this.url];
           }else{
             this.src="";
           }
         }
       },
       onImageLoad(){
         this.$emit("load");
       },
     }
  }
